/* Global Rules */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  pointer-events: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.container {
  padding: 0px 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Small */
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 750px;
  }
}

/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }
}

/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
